import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { Button } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BaseTypography } from '../../../base/components/BaseTypography';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import HasAnyPermission from '../../../base/components/UserProvider/HasAnyPermission';
import {
  DeletePlaylistTemplateFolderDocument,
  EditPlaylistTemplateFolderDocument,
  GetPlaylistTemplateFolderDocument,
  GetPlaylistTemplatesDocument,
  GetPlaylistTemplatesFoldersDocument,
  GetPlaylistTemplatesQueryVariables,
  GetUnassignedPlaylistTemplatesDocument,
  PlaylistTemplateListFragmentFragment,
  UserPermission,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../lib/strings';
import ContentWrapper from '../../components/ContentWrapper';
import { ListView } from '../../components/ListView';
import PageHeader from '../../components/PageHeader';
import { PlaylistTemplateFolderDetail } from './components/PlaylistTemlateFolderDetail';
import PlaylistTemplateCard from './components/PlaylistTemplateCard';
import PlaylistTemplateFolderAddDialog from './components/PlaylistTemplateFolderAddDialog';
import PlaylistTemplateFolderDialog from './components/PlaylistTemplateFolderDialog';

const PlaylistTemplatesFolderPage = () => {
  const { _t } = useTranslationPrefix('PlaylistTemplatesFolderPage');
  const [dialog, setDialog] = useState<
    null | 'name' | 'addPlaylist' | 'deleting' | 'assignment'
  >(null);
  const navigate = useNavigate();

  const { folderId } = useParams();

  const [editPlaylistFolder] = useMutation(EditPlaylistTemplateFolderDocument);
  const [removePlaylistFolder] = useMutation(
    DeletePlaylistTemplateFolderDocument,
    {
      refetchQueries: [getOperationName(GetPlaylistTemplatesFoldersDocument)!],
    },
  );

  const { data, refetch, error, loading } = useQuery(
    GetPlaylistTemplateFolderDocument,
    {
      variables: {
        input: {
          id: folderId!,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const handleRemovePlaylist = (playlistTemplateId: string) => {
    const playlistTemplateIds = data?.folder.playlistTemplates
      .filter((playlistTemplate) => playlistTemplate.id !== playlistTemplateId)
      .map((item) => item.id);
    // TODO show confirmation popup
    editPlaylistFolder({
      variables: {
        input: {
          id: folderId!.toString(),
          playlistTemplateIds,
        },
      },
      refetchQueries: [
        getOperationName(GetPlaylistTemplateFolderDocument)!,
        getOperationName(GetPlaylistTemplatesDocument)!,
        getOperationName(GetUnassignedPlaylistTemplatesDocument)!,
      ],
    });
  };

  const handleRemoveFolder = () => {
    removePlaylistFolder({
      variables: {
        input: {
          id: folderId!,
        },
      },
      onCompleted: () => {
        navigate(`/playlist-templates`);
      },
    });
  };

  const renderValue = (template: PlaylistTemplateListFragmentFragment) => {
    return (
      <PlaylistTemplateCard template={template} onRemove={handleRemovePlaylist}>
        <BaseTypography variant="body3" color="grey.500">
          {_t('createdBy', 'Created by: {{ name }}', {
            name: joinStrings(
              ' ',
              template.owner.firstName,
              template.owner.lastName,
            ),
          })}
        </BaseTypography>
      </PlaylistTemplateCard>
    );
  };

  return (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Folder detail')} back>
        <HasAnyPermission
          val={[
            UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES,
            UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
          ]}
        >
          <Button
            id="add-playlist"
            variant="contained"
            color="primary"
            onClick={() => setDialog('addPlaylist')}
          >
            {_t('addPlaylist', 'Add playlist')}
          </Button>
          <Button
            id="assign-folder"
            variant="contained"
            color="primary"
            component={Link}
            to="./assign"
          >
            {_t('assign', 'Assign')}
          </Button>
        </HasAnyPermission>
      </PageHeader>
      <PlaylistTemplateFolderDetail
        data={data}
        onOpenEdit={() => setDialog('name')}
        onDelete={() => setDialog('deleting')}
      />

      <ListView<
        PlaylistTemplateListFragmentFragment,
        GetPlaylistTemplatesQueryVariables
      >
        data={data?.folder.playlistTemplates || []}
        rowKey="id"
        renderValue={renderValue}
        loading={loading}
        hideEmptyMessage={true}
        columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
      />

      <PlaylistTemplateFolderAddDialog
        open={dialog === 'addPlaylist'}
        onClose={() => setDialog(null)}
        addedPlaylistTemplates={data?.folder.playlistTemplates?.map((item) => ({
          id: item.id,
          name: item.name,
        }))}
        folderId={folderId}
      />
      <PlaylistTemplateFolderDialog
        open={dialog === 'name'}
        folderId={folderId}
        onClose={() => setDialog(null)}
        folderName={data?.folder.name}
      />
      <ConfirmDialog
        open={dialog === 'deleting'}
        title={_t(
          'confirmDeleteDialogTitle',
          'You wish to remove this folder?',
        )}
        onClose={() => setDialog(null)}
        confirmButtonColor="error"
        onConfirm={handleRemoveFolder}
      />
    </ContentWrapper>
  );
};

export default PlaylistTemplatesFolderPage;

export const MAX_UPLOAD_FILE_SIZE = 10000000; // 10MB
export const MAX_UPLOAD_FILES = 5;
export const DATE_FORMAT = 'd. M. y';
export const DATE_TIME_FORMAT = 'd. M. y HH:mm';
export const PHONE_PREFIXES = ['+420'];
export const PHONE_NO_REGEX = /^[0-9]{9}$/;
export const TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT = 365;
export const TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT = 30;
export const MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT = 36500;
export const MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT = 36500;
export const LANGUAGES = ['cs', 'en', 'de'];
export const SCENE_PARAM_ORDER_PREFERENCE = [
  'side',
  'runTime',
  'difficulty',
  'position',
  'lang',
];

import { useNavigate } from 'react-router-dom';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  PlaylistTemplateListFragmentFragment,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import PlayListTemplatesList from '../../PlaylistTemplates/components/PlaylistTemplatesList';
import UserTrainingList from '../../Trainings/components/UserTrainingList';
import SessionHistoryList from './SessionHistoryList';
import { UserDetailFilter } from './UserDetailTabs';

interface Props {
  activeTab: UserDetailFilter;
  userId?: string | null;
}

const UserDetailList = ({ activeTab, userId }: Props) => {
  const navigate = useNavigate();
  const handlePlaylistClick = (
    playlist: PlaylistTemplateListFragmentFragment,
  ) => {
    navigate({
      pathname: '/playlist/create-session',
      search: `?userId=${userId}&templateId=${playlist.id}`,
    });
  };
  switch (activeTab) {
    case UserDetailFilter.HISTORY:
      return <SessionHistoryList userId={userId!} />;
    case UserDetailFilter.TRAINING:
      return (
        <HasPermission val={UserPermission.VIEW_TRAINING_ASSIGNMENTS}>
          <UserTrainingList
            userId={userId}
            features={{ delete: true }}
            columns={{ xs: 1, sm: 3, lg: 4 }}
          />
        </HasPermission>
      );
    case UserDetailFilter.PLAYLISTS:
      return (
        <PlayListTemplatesList
          columns={{ xs: 1, sm: 3, lg: 4 }}
          sx={{ mt: 0 }}
          onClick={handlePlaylistClick}
        />
      );
    default:
      return null;
  }
};

export default UserDetailList;

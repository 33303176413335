import { Chip } from '@mui/material';
import { Box } from '@mui/system';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { compareByOrdinalAndProp } from '../../../lib/compare';
import { SceneParametr, SceneParametrValue } from './PlaylistTypes';

interface Props {
  param: SceneParametr;
  required?: boolean;
  selectedId?: string;
  enabledIds?: string[];
  onChange: (valueId?: string) => void;
}

const PlaylistDialogParam = ({
  param,
  required,
  selectedId,
  enabledIds,
  onChange,
}: Props) => {
  const renderValue = (value: SceneParametrValue) => {
    const selected = selectedId === value.id;

    const handleClick = () => {
      if (!selected) {
        onChange(value.id);
      } else if (!required) {
        onChange();
      }
    };

    return (
      <Chip
        id={`params-value-${value.id}`}
        disabled={!enabledIds?.includes(value.id)}
        key={value.id}
        variant={selected ? 'filled' : 'outlined'}
        label={
          <BaseTypography
            color={selected ? 'primary.contrastTest' : 'text.primary'}
          >
            {value.name_t}
          </BaseTypography>
        }
        onClick={handleClick}
        color={selected ? 'primary' : 'offwhite'}
      />
    );
  };

  return (
    <Box position="relative">
      <BaseTypography variant="h5" textAlign={'center'}>
        {param.name_t}
      </BaseTypography>
      <Flex gap={2} pt={2} justifyContent="center">
        {param.values.sort(compareByOrdinalAndProp('name_t')).map(renderValue)}
      </Flex>
    </Box>
  );
};

export default PlaylistDialogParam;
